const sharedConfig = require("../service/sharedConfiguration");
const rangeUrl = sharedConfig.default.getApiBaseUrl("range");
const userUrl = sharedConfig.default.getApiBaseUrl("user");
const configUrl = sharedConfig.default.getApiBaseUrl("configuration");
const customerUrl = sharedConfig.default.getApiBaseUrl("customer");
const cartservicesUrl = sharedConfig.default.getApiBaseUrl("cartservices");
const catalogBasePath = sharedConfig.default.getApiBaseUrl("catalog");

const APIEndpoints = {
	//Configuration
	refreshpreferences: `${configUrl}/api/configs/refresh/preferences{?market}`,
	findConfig: `${configUrl}/api/configs/find/{key}`,
	envConfig: `${configUrl}/api/configs/environment`,
	appReleaseConfig: `${configUrl}/api/configs/release/latest`,
	authConfig: `${configUrl}/api/configs/auth`,
	accessToken: `${configUrl}/api/configs/auth/accesstoken`,
	productConfig: `${configUrl}/api/configs/productconfig/{key}/{buCode}/{productName}`,

	// NEW PLANNER APIs
	webplanners: "/api/planner/webPlanner/{country}/{language}",
	webplannersById: "/api/planner/webPlanner/{country}/{language}/{id}",
	webplannerItemList: "/api/planner/webPlanner/find/v1/itemlist/{country}/{language}/{id}",
	kitchenPlannersV1: "/api/planner/kitchenPlanner/v1/getdesigns/{country}/{language}/{id}{?buCode}",
	kitchenPlannersById: "/api/planner/kitchenPlanner/getdesigndetail/{country}/{bu}/{language}/{designId}{?plannersource,source}",
	kitchenPlannersSendInteraction: "/api/planner/kitchenPlanner/interaction/send",

	//catalogs/v1/
	topcatalogs: "/api/catalogs/v1/{countryCode}/{language}/{type}/top-categories{?bu,locale}",
	topRangecategories: "/api/catalogs/v1/{countryCode}/{language}/top-categories",
	rangeSubcategories: "/api/catalogs/v1/{countryCode}/{language}/category/{catId}",
	rangecategoryitems: `${catalogBasePath}/api/catalogs/v1/{countryCode}/{language}/category/items/{catId}{?buCode,facets,limit}`,

	categoryReport: "/api/catalogs/v1/report/{country}",

	// User
	homeUser: `${userUrl}/api/userservices/{userid}/store-login{?override}`,
	homeStore: `${userUrl}/api/userservices/store/{bu}{?userid}`,
	userStores: `${userUrl}/api/userservices/{userid}/stores`,
	storePreferences: `${userUrl}/api/userservices/store/{bu}/preferences/{country}{?forceRefresh}`,
	setUserSetting: `${userUrl}/api/userservices/{userid}/store-settings`,
	userPrivileges: `${userUrl}/api/userservices/privileges/{buCode}`,
	userHistory: `${userUrl}/api/userservices/history`,
	userHistoryV1: `${userUrl}/api/userservices/history/v1`,
	userSessionContext: `${userUrl}/api/userservices/sessionContext`,
	survey: `${userUrl}/api/userservices/survey`,

	// Store
	allStores: `${userUrl}/api/store/all`,
	storesByLocale: `${userUrl}/api/store/locale{?locale}`,
	storeInfo: `${userUrl}/api/store/v1/{buCode}/info{?forceRefresh}`,
	getStoreMap: `${userUrl}/api/store/{buCode}/storemap`,
	uploadStoreMap: `${userUrl}/api/store/{buCode}/uploadmap`,
	deleteStoreMap: `${userUrl}/api/store/{buCode}/deletemap`,

	// Range
	retailItemsBulk: `${rangeUrl}/api/range/mfs/retail-item/retail-items/list{?bu}`,
	retailItems: `${rangeUrl}/api/range/mfs/retail-item/retail-items{?bu,id}`,
	retailItem: `${rangeUrl}/api/range/mfs/retail-item/retail-items/{id}{?bu}`,
	relatedRetailItems: `${rangeUrl}/api/range/mfs/retail-item/add-ons/{id}{?bu,type}`,
	sprchildItems: `${rangeUrl}/api/range/mfs/retail-item/child-items/{id}{?bu}`,
	seriesitems: `${rangeUrl}/api/range/mfs/retail-item/retail-items/series/{id}{?bu}`,
	retailItemItemsStock: `${rangeUrl}/api/range/mfs/retail-item/get-items-stock{?id,bu,fetchlatest}`,
	retailItemDetailStock: `${rangeUrl}/api/range/mfs/retail-item/get-item-detail-stock{?bu,id}`,
	retailItemGprs: `${rangeUrl}/api/range/mfs/retail-item/gpr-items/{id}{?bu}`,
	retailItemStock: `${rangeUrl}/api/range/mfs/retail-item/stock`,
	retailItemSearch: `${rangeUrl}/api/range/mfs/retail-item/search{?bu,q,type,page,limit,fq,facets,facetfield,sort,skipdatefilter}`,
	upptackaService: `${rangeUrl}/api/range/upptacka/{id}`,

	getPtag: `${rangeUrl}/api/range/ptag/{buCode}/{id}`,

	visualIntelligenceSearch: `${rangeUrl}/api/range/scan/products/visual-search/search`,
	visualIntelligenceUpload: `${rangeUrl}/api/range/scan/products/visual-search/upload/{id}`,

	receiptSearch: `${rangeUrl}/api/range/receipt/search{?tillNo,receipt,buCode,date,swap}`,
	receiptDetails: `${rangeUrl}/api/range/receipt/details{?recieptNo}`,

	pubSubPublish: "/api/pubsub/publish",

	isomorderreschedule: "/api/cartservices/v1/order-modify/isom-order-reschedule/{orderNoSrc}/{orderNo}",
	isomdelivery: "/api/cartservices/v1/customer-promise/{region}/delivery-arrangements",
	isomtimewindows: "/api/cartservices/v1/customer-promise/{region}/time-windows",
	orderServicescentiroProvidedServicesadditionaltimewindows:
		"/api/cartservices/v1/order-promise/centiro/provided-services/additional-time-windows/{countryCode}/{buCode}{?zipCode}",
	centiroProposal: "/api/cartservices/v1/order-promise/centiro/proposal/{countryCode}/{buCode}{?zipCode}",
	aligncentiroproposal: "/api/cartservices/v1/order-promise/centiro/align-proposal/{countryCode}/{buCode}{?zipCode}",

	orderServicesDeliveryTimeWindows: "/api/cartservices/v1/order-promise/delivery-time-windows/{countryCode}/{buCode}{?zipCode}",

	orderServicesProvidedTimeWindows: "/api/cartservices/v1/order-promise/provided-time-windows",

	orderServicesProvidedServices: "/api/cartservices/v1/order-promise/provided-services/{countryCode}/{buCode}{?zipCode}",
	orderServicesOptions: "/api/cartservices/v1/order-promise/service-options/{countryCode}/{buCode}{?zipCode}",
	orderServicesProposal: "/api/cartservices/v1/order-promise/service-proposal/{countryCode}/{buCode}{?zipCode}",
	questionnaires: "/api/cartservices/v1/questionnaires/{deliveryType}/{countryCode}/{buCode}{?serviceTypes,deliveryIds}",
	orderQuestionnaires: "/api/cartservices/v1/questionnaires/order/questions/{orderNoSource}/{orderNo}",

	orderCapture: "/api/cartservices/v1/order-capture/{orderType}",

	outputConfirmation: "/api/cartservices/v1/order-output/{outputType}/confirmation",
	userMergeOrder: "/api/cartservices/v1/order-search/customer/merge-orders/store/{buCode}/{customerId}{?noofdays,customerType}",
	coworkerOrdersByBu: "/api/cartservices/v1/order-search/coworker/{countryCode}/{buCode}",
	getOrder: "/api/cartservices/v1/order-retrieval/{orderNo}{?fetchallowedModification,includeBarCode}",
	searchOrder: "/api/cartservices/v1/order-search/order-search/{countryCode}{?orderNo}",
	customerOrdersUrl: "/api/cartservices/v1/order-search/customer/{countryCode}/{customerId}",
	webshoppinglistUrl: "/api/cartservices/v1/order-search/customer/web-favs-list/{countryCode}/{customerId}{?buCode,customertype}",
	orderAmountUrl: "/api/cartservices/v1/shoppingcart/sales-price/calculate-cart-totals/{countryCode}/{buCode}",
	salesPriceUrl: "/api/cartservices/v1/shoppingcart/sales-price/items/{countryCode}/{buCode}{?id}",

	deliveryAndServicePrice: "/api/cartservices/v1/shoppingcart/service-price",

	cancelOrder: "/api/cartservices/v1/order-cancel/{type}/{id}",
	converttoorder: "/api/cartservices/v1/order-modify/convert/shoppinglist-to-order",
	createQuotation: "/api/cartservices/v1/manage-quotation/create-quotation",
	cancelQuotation: "/api/cartservices/v1/manage-quotation/cancel-quotation",
	updateQuotation: "/api/cartservices/v1/manage-quotation/update-quotation",
	viewQuotation: "/api/cartservices/v1/manage-quotation/get-quotation/{quoteNo}",
	searchQuotation: "/api/cartservices/v1/manage-quotation/search-quotation{?query}",
	getQuotationProjects: "/api/cartservices/v1/manage-quotation/customer-projects/{partyUID}",
	isomOrderModify: "/api/cartservices/v1/order-modify/isom-order/{orderNoSrc}/{orderNo}",
	submitOrderForm: `${cartservicesUrl}/api/cartservices/v1/order-modify/submit-form`,

	paymentCreditCheck: `${cartservicesUrl}/api/cartservices/v1/payments/validate`,

	// CBD
	cbdService: "/api/cartservices/v1/order-promise/cbd/service/{country}/{type}/{buCode}",

	//Customer
	customerLayout: `${customerUrl}/api/customerservice/layout/{country}/{language}/{bu}`,
	foreignCustomerLayout: `${customerUrl}/api/customerservice/layout/foreigncustomerlayout/{countryCode}`,
	vatServiceCheck: `${customerUrl}/api/customerservice/layout/validatevatnumber/{countrycode}/{vatnumber}`,
	customerAddressAutoComplete: `${customerUrl}/api/customerservice/address/autocomplete`,
	bcmCustomerAddressAutoComplete: `${customerUrl}/api/customerservice/address/autocompletebusiness`,
	customerAddressDetails: `${customerUrl}/api/customerservice/address/details`,
	customerAddressSuggestionsV1: `${customerUrl}/api/customerservice/address/v1/address-suggestions`,
	customerAddressDetailsV1: `${customerUrl}/api/customerservice/address/v1/details`,
	customerProfileUpdate: `${customerUrl}/api/customerservice/profile/update{?id,countryCode}`,
	customerProfileDelete: `${customerUrl}/api/icmdelete{?id,countryCode}`,
	customerProfileCreate: `${customerUrl}/api/customerservice/profile/create{?buCode,countryCode}`,
	customerProfileConvertIcm: `${customerUrl}/api/customerservice/profile/converticm{?buCode,countryCode}`,
	customerProfileDetail: `${customerUrl}/api/customerservice/profile/detail{?id,contextType,countryCode,isbcm}`,
	customerInvoiceDetail: `${customerUrl}/api/customerservice/invoice/detail/{countryCode}/{id}`,
	customerInvoiceUpdate: `${customerUrl}/api/customerservice/invoice/update/{countryCode}/{id}`,
	customerProfileDetailById: `${customerUrl}/api/customerservice/profile/customerdetail/{countryCode}/customerid/{partyId}`,
	customerProfileDetailByFamilyNo: `${customerUrl}/api/customerservice/profile/customerfamilydetail/{countryCode}/familynumber/{familyNo}`,
	bcmCustomerProfileCreate: `${customerUrl}/api/customerservice/profile/bcm/create{?contactPersonId,countryCode}`,
	bcmCustomerProfileDetail: `${customerUrl}/api/customerservice/profile/bcm/detail{?businessCustomerId,contactPersonId,contextType,countryCode}`,
	bcmCustomerProfileAdd: `${customerUrl}/api/customerservice/profile/bcm/add{?contactPersonId,countryCode}`,
	bcmCustomerProfileUpdate: `${customerUrl}/api/customerservice/profile/bcm/update{?contactPersonId,countryCode}`,
	bcmCustomerProfileAddressesUpdate: `${customerUrl}/api/customerservice/profile/bcm/addresses/update{countryCode}`,
	bcmContactPerson: `${customerUrl}/api/customerservice/profile/bcm/contact-persons/`,
	bcmBusinessProfile: `${customerUrl}/api/customerservice/profile/bcm/business-customers/`,
	bcmBusinessAddresses: `${customerUrl}/api/customerservice/profile/bcm/business-customers/{businessCustomerId}/addresses/`,
	bcmContactPersonAddresses: `${customerUrl}/api/customerservice/profile/bcm/contact-persons/{contactPersonId}/addresses/`,
	bcmContactPersonContactMethods: `${customerUrl}/api/customerservice/profile/bcm/contact-persons/{contactPersonId}/contact-methods/`,
	getCustomerByIdAndType: `${customerUrl}/api/customerservice/profile/getCustomerById{?buCode,countryCode,id,type}`,
	ciamAddressPopulate: `${customerUrl}/api/customerservice/ciam/address-populate/getids{?type,id}`,
	ciamAddressPopulateZipcode: `${customerUrl}/api/customerservice/ciam/address-populate/getzipcode{?province,city,district,street}`,
	ciamSmsSend: `${customerUrl}/api/customerservice/ciam/address-populate/smssend`,
	ciamSmsVerify: `${customerUrl}/api/customerservice/ciam/address-populate/smsverify`,
	ciamCustomerFindByPhoneNumber: `${customerUrl}/api/customerservice/profile/customerdetail/{phoneNumber}`,
	ciamAddressUpdate: `${customerUrl}/api/customerservice/ciam/address-handler/delivery-address`,

	//Bcm Customer
	bcmCustomerAddressesAdd: `${customerUrl}/api/bcmcustomerservice/addresses/add/{countryCode}`,
	bcmCustomerAddressesUpdate: `${customerUrl}/api/bcmcustomerservice/addresses/update/{countryCode}`,
	bcmCustomerContactMethodsAdd: `${customerUrl}/api/bcmcustomerservice/contactmethods/add/{countryCode}`,
	bcmCustomerContactMethodsUpdate: `${customerUrl}/api/bcmcustomerservice/contactmethods/update/{countryCode}`
};

export default APIEndpoints;
